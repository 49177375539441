<template>
  <div>
    <el-row style="padding-bottom: 10px;line-height: 40px">
      <el-col :span="24">
        <el-input ref="boxSn" v-model.trim="formData.boxSn" placeholder="请输入或扫包材条码" @keyup.enter.native="search" style="width: 250px" clearable></el-input>
        <el-button type="primary" style="margin-left: 10px" :loading="loading" @click="search">查询</el-button>
        <el-button type="primary" style="margin-left: 100px" @click="submit" :loading="loading">报废</el-button>
        <MessageComponent ref="searchMessage"/>
      </el-col>
    </el-row>
    <RecyclableBoxInfoComponent :recyclable-box="recyclableBox"/>
  </div>
</template>

<script>
import RecyclableBoxInfoComponent from "@/views/recyclableBox/RecyclableBoxInfoComponent";
import MessageComponent from "@/components/MessageComponent";

export default {
  name: "RecyclableBoxScrap",
  components: {MessageComponent, RecyclableBoxInfoComponent},
  data() {
    return {
      formData: {
        boxSn: ''
      },
      recyclableBox: {
        boxSn: '',
        boxStatusName: ''
      },
      loading: false,
      canCommit: false
    }
  },
  methods: {
    clearData() {
      this.formData.boxSn = ''
      this.recyclableBox = {}
      this.canCommit = false
    },
    search() {
      this.canCommit = false
      this.$refs.searchMessage.clear();
      if (!this.formData.boxSn) {
        return this.$refs.searchMessage.showError('请输入或扫包材条码');
      }
      this.formData.boxSn = this.formData.boxSn.toUpperCase();
      if (!this.formData.boxSn.startsWith("BOX") || this.formData.boxSn.length != 23) {
        return this.$refs.searchMessage.showError('扫包材条码不正确');
      }

      this.loading = true

      this.$axios.post('recyclableBox/getInfo', this.formData.boxSn).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.searchMessage.showError(res.message);
        }

        if (!res.data.recyclableBox) {
          return this.$refs.searchMessage.showError('包材条码不正确');
        }

        this.recyclableBox = res.data.recyclableBox;
        if (this.recyclableBox.boxStatus != '50') {
          return this.$refs.searchMessage.showError('该包材不是待报废状态，不能报废。');
        }

        this.canCommit = true;
      }, error => {
        this.loading = false;
        return this.$refs.searchMessage.showError('查询失败，' + error.message);
      })

    },
    submit() {
      if (!this.formData.boxSn) {
        return this.$refs.searchMessage.showError('请输入或扫包材条码');
      }
      if (!this.recyclableBox || !this.recyclableBox.boxSn) {
        return this.$refs.searchMessage.showError('请先查询包材信息');
      }
      if (!this.canCommit) {
        return this.$refs.searchMessage.showError('该包材条码不符合报废作业条件');
      }

      this.loading = true
      this.$axios.post('recyclableBox/scrap', this.formData.boxSn).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$refs.searchMessage.showError(res.message);
        }
        this.$refs.searchMessage.showSuccess('报废成功');
        this.clearData();
        this.$refs.boxSn.focus()
      }, error => {
        this.loading = false;
        return this.$refs.searchMessage.showError('提交失败，' + error.message);
      })


    }
  }
}
</script>

<style scoped>

</style>